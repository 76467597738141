
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

// lazy load the largest components with the most dependencies to increase
// initial load times
const CompanyListings = () => import("@/components/CompanyListings.vue");
const CompanySearch = () => import("@/components/CompanySearch.vue");
import Container from "@/components/Container.vue";
import Hero from "@/components/Hero.vue";
import IpRedirectPrompt from "@/components/IpRedirectPrompt.vue";
import WhatsappBanner from "@/components/WhatsappBanner.vue";

import { GET_JSON_COMPANY_RESULTS } from "@/store/types/actions";

import { MODE } from "@/app.config";

export default Vue.extend({
  name: "Home",
  components: {
    CompanyListings,
    CompanySearch,
    Container,
    Hero,
    IpRedirectPrompt,
    WhatsappBanner,
  },
  computed: {
    ...mapGetters(["countryCode", "countryCodeDisplay"]),
  },
  watch: {
    countryCode: {
      handler() {
        MODE !== "ext" && this.GET_JSON_COMPANY_RESULTS();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([GET_JSON_COMPANY_RESULTS]),
  },
});
