
// @ts-nocheck
import { Button, Spacer } from "@num/component-library";
import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import {
  SET_IP_ADDRESS_PROMPT_DISMISSED,
  SET_COUNTRY_CODE_USER_SELECTED,
} from "@/store/types/mutations";

import { getCountryCodeDisplay, redirectToVersion } from "@/utils/utils";

export default Vue.extend({
  name: "IpRedirectPrompt",
  components: {
    Button,
    Spacer,
  },
  data() {
    return {
      getCountryCodeDisplay,
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["countryCode"]),
    countryCodeIp() {
      return this.settings?.countryCodeIp;
    },
    countryCodeUserSelected() {
      return this.settings?.countryCodeUserSelected;
    },
    ipAddressPromptDismissed() {
      return this.settings?.ipAddressPromptDismissed;
    },
    show() {
      return (
        !this.ipAddressPromptDismissed &&
        this.countryCodeIp &&
        this.countryCode !== this.countryCodeIp
      );
    },
  },
  methods: {
    ...mapMutations([
      SET_COUNTRY_CODE_USER_SELECTED,
      SET_IP_ADDRESS_PROMPT_DISMISSED,
    ]),
    dismissIpAddressPrompt() {
      this.SET_IP_ADDRESS_PROMPT_DISMISSED();
    },
    setIpAsCountryCode() {
      this.SET_COUNTRY_CODE_USER_SELECTED(this.countryCodeIp);
      redirectToVersion(this.countryCodeIp);
    },
  },
});
